<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#FF681F"
      spinner="spinner"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar
      v-model="showSnackbar"
      color="rgba(0, 38, 53, 1)"
      right
      :timeout="timeout"
    >
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center class="mainfont">
      <v-flex xs12 align-self-center>
        <v-card tile class="pa-4">
          <v-layout wrap>
            <v-flex xs12 v-if="hotelData">
  <v-carousel
    v-if="hotelData.additionalImages && hotelData.additionalImages.length > 0"
    height="400px"
  cycle
  autoplay
  :interval="5000"
  hide-delimiters
  show-arrows
  >
    <v-carousel-item
      v-for="(image, index) in hotelData.additionalImages"
      :key="index"
      :src="mediaUURL + image"
    >
      <template v-slot:placeholder>
        <ImageLoader />
      </template>
      <v-layout justify-start fill-height>
        <v-flex xs3 sm2 align-self-end mb-4 ml-1 ml-sm-8>
          <v-card
            height="110px"
            width="150px"
            class="pa-1 hidden-sm-and-down"
            v-if="hotelData.coverImages"
          >
            <v-img
              height="100%"
              width="100%"
              v-if="hotelData.coverImages"
              :src="mediaUURL + hotelData.coverImages"
              contain
            >
              <template v-slot:placeholder>
                <ImageLoader />
              </template>
            </v-img>
            <v-img
              height="100%"
              v-else
              src="./../../assets/images/noimg.png"
              contain
            >
              <template v-slot:placeholder>
                <ImageLoader />
              </template>
            </v-img>
          </v-card>
          <v-card
            class="pa-1 hidden-md-and-up"
            height="50px"
            width="60px"
            v-if="hotelData.coverImages"
          >
            <v-img
              height="100%"
              width="100%"
              v-if="hotelData.coverImages"
              :src="mediaUURL + hotelData.coverImages"
              contain
            />
            <v-img
              height="100%"
              v-else
              src="./../../assets/images/noimg.png"
              contain
            >
              <template v-slot:placeholder>
                <ImageLoader />
              </template>
            </v-img>
          </v-card>
        </v-flex>

        <v-flex xs7 sm8 align-self-end mb-4 pl-2 pl-sm-0>
          <span
            style="
              font-family: LexendFont;
              font-weight: 500;
              font-size: 22px;
              color: white;
            "
            v-if="hotelData.hotelName"
          >
            {{ hotelData.hotelName }}
          </span>
          <br class="hidden-sm-and-up" />
          <span
            style="
              font-family: LexendFont;
              font-weight: 500;
              font-size: 16px;
              color: white;
            "
            v-if="hotelData.hotelCategory"
          >
            ({{ hotelData.hotelCategory }})
          </span>
          <br />
          <span
            class="px-2 py-1"
            style="
              border-radius: 5px;
              font-family: LexendFont;
              font-weight: 500;
              font-size: 10px;
              color: white;
              background-color: rgba(60, 187, 205, 1);
            "
            v-if="hotelData.hotelCategory"
          >
            {{ hotelData.hotelCategory }}
          </span>
        </v-flex>

        <v-flex xs2 align-self-end text-right mb-4 pr-8>
          <v-btn
            style="border-radius: 2px"
            class="hidden-sm-and-down"
            color="grey"
            @click="$router.push('/hotelEdit?id=' + $route.query.id)"
          >
            <span
              style="
                font-family: LexendFont;
                font-weight: 600;
                font-size: 15px;
                color: white;
              "
            >
              Edit
            </span>
          </v-btn>

          <v-btn
            class="hidden-md-and-up"
            style="border-radius: 2px"
            text
            @click="$router.push('/hotelEdit?id=' + $route.query.id)"
          >
            <span
              style="
                font-family: LexendFont;
                font-weight: 600;
                font-size: 15px;
                color: white;
              "
            >
              <v-icon>mdi-pencil</v-icon>
            </span>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-carousel-item>
  </v-carousel>

  <v-img
    v-else
    width="1251px"
    :height="
      $vuetify.breakpoint.name == 'xs' ||
      $vuetify.breakpoint.name == 'sm'
        ? '600px'
        : $vuetify.breakpoint.name == 'md'
        ? '600px'
        : $vuetify.breakpoint.name == 'lg'
        ? '300px'
        : '300px'
    "
    src="./../../assets/images/noimg.png"
  >
    <v-layout justify-start fill-height>
      <v-flex xs12 sm2 align-self-end mb-4 ml-4>
        <v-card class="pa-1" height="110px" width="110px">
          <v-img
            height="110px"
            cover
            v-if="hotelData.coverImages"
            :src="mediaUURL + hotelData.coverImages"
            contain
          >
            <template v-slot:placeholder>
              <ImageLoader />
            </template>
          </v-img>
        </v-card>
      </v-flex>

      <v-flex xs8 align-self-end mb-4>
        <span
          style="
            font-family: LexendFont;
            font-weight: 500;
            font-size: 22px;
            color: white;
          "
          v-if="hotelData.hotelName"
        >
          {{ hotelData.hotelName }}
        </span>
        <span
          style="
            font-family: LexendFont;
            font-weight: 500;
            font-size: 16px;
            color: white;
          "
          v-if="hotelData.hotelCategory"
        >
          ({{ hotelData.hotelCategory }})
        </span>
        <br />
        <span
          class="px-2 py-1"
          style="
            border-radius: 5px;
            font-family: LexendFont;
            font-weight: 500;
            font-size: 10px;
            color: white;
            background-color: rgba(60, 187, 205, 1);
          "
          v-if="hotelData.hotelCategory"
        >
          {{ hotelData.hotelCategory }}
        </span>
      </v-flex>
    </v-layout>
  </v-img>
</v-flex>

          </v-layout>
          <v-layout
            justify-center
            justify-sm-start
            wrap
            style="background-color: rgba(245, 242, 240, 1)"
            pa-0
            pa-sm-4
            my-4
          >
            <v-flex xs12 sm2 align-self-center text-center>
              <span
                style="
                  font-family: LexendFont;
                  font-weight: 600;
                  font-size: 10px;
                  color: rgba(144, 144, 144, 1);
                  text-transform: uppercase;
                "
                >Category</span
              >
              <br />
              <span
                style="
                  font-family: LexendFont;
                  font-weight: 600;
                  font-size: 15px;
                  color: rgba(64, 64, 64, 1);
                "
                v-if="hotelData.hotelCategory"
              >
                {{ hotelData.hotelCategory }}</span
              >
            </v-flex>
            <v-flex xs12 sm2 align-self-center text-center>
              <span
                style="
                  font-family: LexendFont;
                  font-weight: 600;
                  font-size: 10px;
                  color: rgba(144, 144, 144, 1);
                  text-transform: uppercase;
                "
                >Total Rooms</span
              >
              <br />
              <span
                style="
                  font-family: LexendFont;
                  font-weight: 600;
                  font-size: 15px;
                  color: rgba(64, 64, 64, 1);
                "
                v-if="hotelData.totalRooms"
              >
                {{ hotelData.totalRooms }}</span
              >
            </v-flex>
            <v-flex xs12 sm2 align-self-center text-center>
              <span
                style="
                  font-family: LexendFont;
                  font-weight: 600;
                  font-size: 10px;
                  color: rgba(144, 144, 144, 1);
                  text-transform: uppercase;
                "
                >Total Floors</span
              >
              <br />
              <span
                style="
                  font-family: LexendFont;
                  font-weight: 600;
                  font-size: 15px;
                  color: rgba(64, 64, 64, 1);
                "
                v-if="hotelData.totalFloors"
              >
                {{ hotelData.totalFloors }}</span
              >
            </v-flex>
            <v-flex xs12 sm2 align-self-center text-center>
              <span
                style="
                  font-family: LexendFont;
                  font-weight: 600;
                  font-size: 10px;
                  color: rgba(144, 144, 144, 1);
                  text-transform: uppercase;
                "
                >Total Restaurents</span
              >
              <br />
              <span
                style="
                  font-family: LexendFont;
                  font-weight: 600;
                  font-size: 15px;
                  color: rgba(64, 64, 64, 1);
                "
                v-if="hotelData.numberOfRestaurants"
              >
                {{ hotelData.numberOfRestaurants }}</span
              >
            </v-flex>
            <v-flex xs12 sm2 align-self-center text-center>
              <span
                style="
                  font-family: LexendFont;
                  font-weight: 600;
                  font-size: 10px;
                  color: rgba(144, 144, 144, 1);
                  text-transform: uppercase;
                "
                v-if="hotelData.expiryType == 'licenseExpiry'"
                >License expiry</span
              >
              <span
                style="
                  font-family: LexendFont;
                  font-weight: 600;
                  font-size: 10px;
                  color: rgba(144, 144, 144, 1);
                  text-transform: uppercase;
                "
                v-if="hotelData.expiryType == 'insuranceExpiry'"
                >Insurance expiry</span
              >
              <br />
              <span
                style="
                  font-family: LexendFont;
                  font-weight: 600;
                  font-size: 15px;
                  color: rgba(64, 64, 64, 1);
                "
                v-if="hotelData.expiryDate"
              >
                {{ formatDate(hotelData.expiryDate) }}</span
              >
            </v-flex>
         
          </v-layout>
          <v-layout wrap justify-start py-2>
            <v-flex xs12 sm6 md3 pr-0 pr-sm-1 pr-md-2>
              <v-btn block color="#ff6200" dark @click="$router.push('/viewRooms?id=' + $route.query.id)">View / Edit Room</v-btn>
              </v-flex>
            <v-flex xs12 sm6 md3 pr-0 pr-sm-1 pr-md-2>
              <v-btn block color="#ff6200" dark   @click="$router.push('/CreateRooms?id=' + $route.query.id)">Create Room</v-btn>
              </v-flex>
              </v-layout>
     
          <v-layout wrap>
            <v-flex xs12 lg10>
              <v-layout wrap mt-2 v-if="hotelData">
                <v-flex
                  xs12
                  px-0
                  px-md-2
                 ><span  style="
                    font-family: LexendFont;
                    font-weight: 500;
                    font-size: 22px;
                  "
                  >OverView </span></v-flex
                >
              <v-flex xs12 py-2>
                <v-layout wrap>
                  <v-flex xs12 sm3 pb-1 align-self-center text-left px-2   v-if="hotelData.hotelType">
                        <span
                          style="
                            font-family: LexendFont;
                            font-weight: 500;
                            font-size: 18px;
                          "
                          >Hotel Type : </span
                        ><br />
                        <span
                          v-if="hotelData.hotelType.hotel_type_name"
                          class="pt-2"
                          style="
                            color: black;
                            font-family: LexendFont;
                            font-weight: 300;
                            font-size: 16px;
                          "
                        >
                          {{ hotelData.hotelType.hotel_type_name }}</span
                        >
                        <span v-else>-</span>
                      </v-flex>
                      <v-flex xs12 sm3 pb-1 align-self-center text-left px-2 v-if="hotelData.is24HrsCheckin==true">
                        <span
                          style="
                            font-family: LexendFont;
                            font-weight: 500;
                            font-size: 18px;
                          "
                          >Check-In/Out :</span
                        ><br />
                        <span
                          class="pt-2"
                          style="
                            color: black;
                            font-family: LexendFont;
                            font-weight: 300;
                            font-size: 16px;
                          "
                        >
                          24 Hrs check-in</span
                        >
</v-flex>
                      <v-flex xs12 sm2 pb-1 align-self-center text-left px-2 v-else>
                        <span
                          style="
                            font-family: LexendFont;
                            font-weight: 500;
                            font-size: 18px;
                          "
                          >Check in :</span
                        ><br />
                        <span
                          v-if="hotelData.checkInTime"
                          class="pt-2"
                          style="
                            color: black;
                            font-family: LexendFont;
                            font-weight: 300;
                            font-size: 16px;
                          "
                        >
                          {{ hotelData.checkInTime }}</span
                        >
                        <span v-else>12:00</span>
                      </v-flex>
                      <v-flex xs12 sm2 pb-1 align-self-center text-left px-2 v-if="hotelData.is24HrsCheckin==false">
                        <span
                          style="
                            font-family: LexendFont;
                            font-weight: 500;
                            font-size: 18px;
                          "
                          >Check out : </span
                        ><br />
                        <span
                          v-if="hotelData.checkOutTime"
                          class="pt-2"
                          style="
                            color: black;
                            font-family: LexendFont;
                            font-weight: 300;
                            font-size: 16px;
                          "
                        >
                          {{ hotelData.checkOutTime }}</span
                        >
                        <span v-else>12:00</span>
                      </v-flex>
                      <v-flex xs12 sm3 pb-1 align-self-center text-left px-2>
                        <span
                          style="
                            font-family: LexendFont;
                            font-weight: 500;
                            font-size: 18px;
                          "
                          >Whatsapp number : </span
                        ><br />
                        <span
                          v-if="hotelData.whatsappNumber"
                          class="pt-2"
                          style="
                            color: black;
                            font-family: LexendFont;
                            font-weight: 300;
                            font-size: 16px;
                          "
                        >
                          {{ hotelData.whatsappNumber }}</span
                        >
                        <span v-else>-</span>
                      </v-flex>
                      <v-flex xs12 sm2 pb-1 align-self-center text-left px-2>
                        <span
                          style="
                            font-family: LexendFont;
                            font-weight: 500;
                            font-size: 18px;
                          "
                          >Email :</span
                        ><br />
                        <span
                          v-if="hotelData.email"
                          class="pt-2"
                          style="
                            color: black;
                            font-family: LexendFont;
                            font-weight: 300;
                            font-size: 16px;
                          "
                        >
                          {{ hotelData.email }}</span
                        >
                        <span v-else>-</span>
                      </v-flex>
                      <v-flex
                        xs12
                        align-self-center
                        text-left
                        px-2 pb-1
                        v-if="contactDetails.phoneNumber"
                      >
                        <span
                          style="
                            font-family: LexendFont;
                            font-weight: 500;
                            font-size: 18px;
                          "
                          >Other Contacts :</span
                        >
                        <v-layout
                          wrap
                          v-if="contactDetails.phoneNumber.length > 0"
                         
                        >
                          <v-flex
                            xs12 sm3
                            v-for="(item2, i) in contactDetails.phoneNumber"
                            :key="i"
                          >
                            <span
                              style="
                                font-family: LexendFont;
                                font-weight: 300;
                                font-size: 16px;
                                color: black;
                              "
                            >
                              {{ item2 }}</span
                            >
                          </v-flex>
                          <v-flex
                            xs12 sm4
                            v-for="(item2, i) in contactDetails.emailId"
                            :key="i"
                          >
                            <span
                              style="
                                font-family: LexendFont;
                                font-weight: 300;
                                font-size: 16px;
                                color: black;
                              "
                            >
                              {{ item2 }}</span
                            >
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
              </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 lg2 align-self-start>
              <v-layout wrap justify-start justify-lg-center pl-4 pl-md-0>
                <v-flex text-center pt-4>
                  <v-card>
                    <v-layout wrap pa-1>
                      <v-flex xs12> View</v-flex>
                      <v-flex
                        xs12
                        pa-2
                        sm3
                        lg12
                        text-center
                        v-if="hotelData.licenseProof"
                      >
                        <v-btn
                          color="rgba(255, 98, 0, 1)"
                          small
                          block
                          dark
                          download
                          :href="mediaUURL + hotelData.licenseProof"
                          target="_blank"
                        >
                          <v-icon small>mdi-file-outline</v-icon
                          ><span
                            style="
                              font-family: LexendFont;
                              font-weight: 500;
                              font-size: 16px;
                              text-transform: none;
                            "
                            >License
                          </span></v-btn
                        >
                      </v-flex>

                      <v-flex
                        xs12
                        pa-2
                        sm3
                        lg12
                        text-center
                        v-if="hotelData.leaseProof"
                      >
                        <v-btn
                          color="rgba(255, 98, 0, 1)"
                          small
                          block
                          dark
                          download
                          :href="mediaUURL + hotelData.leaseProof"
                          target="_blank"
                        >
                          <v-icon small>mdi-file-outline</v-icon
                          ><span
                            style="
                              font-family: LexendFont;
                              font-weight: 500;
                              font-size: 16px;
                              text-transform: none;
                            "
                            >Lease Proof</span
                          ></v-btn
                        >
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
                <!-- <v-flex text-center pt-4>
                  <v-card>
                    <v-layout wrap pa-1>
                      <v-flex xs12> Edit</v-flex>
                      <v-flex xs12 pa-2 sm3 lg12 text-center>
                        <v-btn
                          color="rgba(255, 98, 0, 1)"
                          small 
                          block
                          dark
                          @click="openFileUploadDialog"
                        >
                          <v-icon small>mdi-file-upload-outline</v-icon
                          ><span
                            style="
                              font-family: LexendFont;
                              font-weight: 500;
                              font-size: 16px;
                              text-transform: none;
                            "
                            >License</span
                          ></v-btn
                        >

                        <v-dialog v-model="fileUploadDialog" max-width="500">
                          <v-card>
                            <v-card-title class="mainfont">
                              <v-layout wrap>
                                <v-flex xs12 pt-1 pb-2>
                                  Edit License
                                </v-flex>
                              </v-layout>
                            </v-card-title>
                            <v-card-text>
                           
                              <input
                                class="mainfont"
                                type="file"
                                ref="fileInput"
                                @change="handleFileChange"
                              />
                            </v-card-text>
                            <v-card-actions class="mainfont">
                              <v-layout wrap justify-center>
                                <v-flex xs3 pr-3 pb-3>
                                  <v-btn
                                    color="rgba(255, 98, 0, 1)"
                                    block
                                    style="text-transform: none"
                                    @click="editHouseboatproof()"
                                  >
                                    <span style="color: white"> Upload </span>
                                  </v-btn>
                                </v-flex>
                                <v-flex xs3 pl-3 pb-3>
                                  <v-btn
                                    color="rgba(255, 98, 0, 1)"
                                    block
                                    style="text-transform: none"
                                    @click="closeFileUploadDialog"
                                  >
                                    <span style="color: white"> Close </span>
                                  </v-btn>
                                </v-flex>
                              </v-layout>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                        <v-dialog v-model="fileUploadDialog2" max-width="500">
                          <v-card>
                            <v-card-title class="mainfont">
                              <v-layout wrap>
                                <v-flex xs12 pt-1 pb-2>
                                  Edit Lease Proof
                                </v-flex>
                              </v-layout>
                            </v-card-title>
                            <v-card-text>
                             
                              <input
                                type="file"
                                ref="fileInput2"
                                @change="handleLeaseProofChange"
                              />
                            </v-card-text>
                            <v-card-actions class="mainfont">
                              <v-layout wrap justify-center>
                                <v-flex xs3 pr-3 pb-3>
                                  <v-btn
                                    color="rgba(255, 98, 0, 1)"
                                    @click="uploadLeaseProof"
                                  >
                                    <span
                                      style="color: white; text-transform: none"
                                    >
                                      Upload
                                    </span>
                                  </v-btn>
                                </v-flex>
                                <v-flex xs3 pl-3 pb-3>
                                  <v-btn
                                    color="rgba(255, 98, 0, 1)"
                                    @click="closeFileUploadDialog2"
                                  >
                                    <span
                                      style="color: white; text-transform: none"
                                    >
                                      Close
                                    </span>
                                  </v-btn>
                                </v-flex>
                              </v-layout>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-flex>

                      <v-flex
                        xs12
                        pa-2
                        sm3
                        lg12
                        text-center
                        v-if="hotelData.leaseProof"
                      >
                        <v-btn
                          @click="fileUploadDialog2 = true"
                          color="rgba(255, 98, 0, 1)"
                          small
                          block
                          dark
                          target="_blank"
                        >
                          <v-icon small>mdi-file-upload-outline</v-icon
                          ><span
                            style="
                              font-family: LexendFont;
                              font-weight: 500;
                              font-size: 16px;
                              text-transform: none;
                            "
                            >Lease Proof</span
                          ></v-btn
                        >
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex> -->
              </v-layout>
            </v-flex>
            <v-flex xs12 sm10 text-left><v-divider></v-divider></v-flex>
            <v-flex xs12>
            <v-layout wrap mt-2 v-if="hotelData">
                <v-flex
                  xs12
                  px-0
                  px-md-2
                 ><span  style="
                    font-family: LexendFont;
                    font-weight: 500;
                    font-size: 22px;
                  "
                  >Bank Details</span></v-flex
                >
              <v-flex xs12 py-2>
                <v-layout wrap>
                  
                     
                      <v-flex xs12 sm3 pb-1 align-self-center text-left px-2>
                        <span
                          style="
                            font-family: LexendFont;
                            font-weight: 500;
                            font-size: 18px;
                          "
                          >Bank Name : </span
                        ><br />
                        <span
                          v-if="hotelData.bankName"
                          class="pt-2"
                          style="
                            color: black;
                            font-family: LexendFont;
                            font-weight: 300;
                            font-size: 16px;
                          "
                        >
                          {{hotelData.bankName}}</span
                        >
                        <span v-else>-</span>
                      </v-flex>
                      <v-flex xs12 sm3 pb-1 align-self-center text-left px-2>
                        <span
                          style="
                            font-family: LexendFont;
                            font-weight: 500;
                            font-size: 18px;
                          "
                          >Branch : </span
                        ><br />
                        <span
                          v-if="hotelData.branchName"
                          class="pt-2"
                          style="
                            color: black;
                            font-family: LexendFont;
                            font-weight: 300;
                            font-size: 16px;
                          "
                        >
                          {{hotelData.branchName}}</span
                        >
                        <span v-else>-</span>
                      </v-flex>
                      <v-flex xs12 sm3 pb-1 align-self-center text-left px-2>
                        <span
                          style="
                            font-family: LexendFont;
                            font-weight: 500;
                            font-size: 18px;
                          "
                          >IFSC :</span
                        ><br />
                        <span
                          v-if="hotelData.ifscCode"
                          class="pt-2"
                          style="
                            color: black;
                            font-family: LexendFont;
                            font-weight: 300;
                            font-size: 16px;
                          "
                        >
                        {{ hotelData.ifscCode }}</span
                        >
                        <span v-else>-</span>
                      </v-flex>
                      <v-flex xs12 sm3 pb-1 align-self-center text-left px-2>
                        <span
                          style="
                            font-family: LexendFont;
                            font-weight: 500;
                            font-size: 18px;
                          "
                          >Acc.Name: </span
                        ><br />
                        <span
                          v-if="hotelData.accountName"
                          class="pt-2"
                          style="
                            color: black;
                            font-family: LexendFont;
                            font-weight: 300;
                            font-size: 16px;
                          "
                        >
                          {{ hotelData.accountName }}</span
                        >
                        <span v-else>-</span>
                      </v-flex>
                      <v-flex xs12 sm3 pb-1 align-self-center text-left px-2>
                        <span
                          style="
                            font-family: LexendFont;
                            font-weight: 500;
                            font-size: 18px;
                          "
                          >Acc.Number: </span
                        ><br />
                        <span
                          v-if="hotelData.accountNumber"
                          class="pt-2"
                          style="
                            color: black;
                            font-family: LexendFont;
                            font-weight: 300;
                            font-size: 16px;
                          "
                        >
                          {{ hotelData.accountNumber }}</span
                        >
                        <span v-else>-</span>
                      </v-flex>
                      <v-flex xs12 sm6 pb-1 align-self-center text-left px-2>
                        <span
                          style="
                            font-family: LexendFont;
                            font-weight: 500;
                            font-size: 18px;
                          "
                          >Cancellation Policy : </span
                        ><br />
                        <span
                          v-if="hotelData.cancellationPolicy"
                          class="pt-2"
                          style="
                            color: black;
                            font-family: LexendFont;
                            font-weight: 300;
                            font-size: 16px;
                          "
                        >
                          {{ hotelData.cancellationPolicy }}</span
                        >
                        <span v-else>-</span>
                      </v-flex>
                      <v-flex xs12 sm6 pb-1 align-self-center text-left px-2>
                        <span
                          style="
                            font-family: LexendFont;
                            font-weight: 500;
                            font-size: 18px;
                          "
                          >Booking Policy : </span
                        ><br />
                        <span
                          v-if="hotelData.bookingPolicy"
                          class="pt-2"
                          style="
                            color: black;
                            font-family: LexendFont;
                            font-weight: 300;
                            font-size: 16px;
                          "
                        >
                          {{ hotelData.bookingPolicy }}</span
                        >
                        <span v-else>-</span>
                      </v-flex>
                    
                    </v-layout>
              </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm10 text-left><v-divider></v-divider></v-flex>
            <v-flex xs12>
            <v-layout wrap mt-2 v-if="hotelData">
                <v-flex
                  xs12
                  px-0
                  px-md-2
                 ><span  style="
                    font-family: LexendFont;
                    font-weight: 500;
                    font-size: 22px;
                  "
                  >Location Details</span></v-flex
                >
              <v-flex xs12 py-2>
                <v-layout wrap>
                  
                     
                      <v-flex xs12 sm3 pb-1 align-self-center text-left px-2>
                        <span
                          style="
                            font-family: LexendFont;
                            font-weight: 500;
                            font-size: 18px;
                          "
                          >Location : </span
                        ><br />
                        <span
                          v-if="hotelData.locationName"
                          class="pt-2"
                          style="
                            color: black;
                            font-family: LexendFont;
                            font-weight: 300;
                            font-size: 16px;
                          "
                        >
                          {{hotelData.locationName}}</span
                        >
                        <span v-else>-</span>
                      </v-flex>
                      <v-flex xs12 sm3 pb-1 align-self-center text-left px-2>
                        <span
                          style="
                            font-family: LexendFont;
                            font-weight: 500;
                            font-size: 18px;
                          "
                          >Main town : </span
                        ><br />
                        <span
                          v-if="hotelData.townName"
                          class="pt-2"
                          style="
                            color: black;
                            font-family: LexendFont;
                            font-weight: 300;
                            font-size: 16px;
                          "
                        >
                          {{hotelData.townName}}</span
                        >
                        <span v-else>-</span>
                      </v-flex>
                      <v-flex xs12 sm3 pb-1 align-self-center text-left px-2>
                        <span
                          style="
                            font-family: LexendFont;
                            font-weight: 500;
                            font-size: 18px;
                          "
                          >Dist. from main town :</span
                        ><br />
                        <span
                          v-if="hotelData.distanceFromMainTown"
                          class="pt-2"
                          style="
                            color: black;
                            font-family: LexendFont;
                            font-weight: 300;
                            font-size: 16px;
                          "
                        >
                        {{ hotelData.distanceFromMainTown }}</span
                        >
                        <span v-else>-</span>
                      </v-flex>
                      <v-flex xs12 sm6 pb-1 align-self-center text-left px-2>
                        <span
                          style="
                            font-family: LexendFont;
                            font-weight: 500;
                            font-size: 18px;
                          "
                          >Location link: </span
                        ><br />
                        <span
                          v-if="hotelData.googleLocation"
                          class="pt-2"
                          style="
                            color: black;
                            font-family: LexendFont;
                            font-weight: 300;
                            font-size: 16px;
                          "
                        >
                          {{ hotelData.googleLocation }}</span
                        >
                        <span v-else>-</span>
                      </v-flex>
                      <v-flex xs12 sm6 pb-1 align-self-center text-left px-2>
                        <span
                          style="
                            font-family: LexendFont;
                            font-weight: 500;
                            font-size: 18px;
                          "
                          >Address : </span
                        ><br />
                        <span
                          v-if="hotelData.address"
                          class="pt-2"
                          style="
                            color: black;
                            font-family: LexendFont;
                            font-weight: 300;
                            font-size: 16px;
                          "
                        >
                          {{ hotelData.address }}</span
                        >
                        <span v-else>-</span>
                      </v-flex>
                    
                    </v-layout>
              </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm10 text-left><v-divider></v-divider></v-flex>

            <v-flex xs12>
              <v-layout wrap mt-2 v-if="FacilitiesData">
                <v-flex
                  xs12
                  px-0
                  px-md-2
                  style="
                    font-family: LexendFont;
                    font-weight: 500;
                    font-size: 22px;
                  "
                  >Amenities</v-flex
                >
                <v-flex xs12 px-0 px-md-2 v-if="isFacility == false"
                  ><span
                    style="
                      font-family: LexendFont;
                      font-weight: 500;
                      font-size: 16px;
                    "
                    >No facilities added</span
                  ></v-flex
                >
             <v-flex xs12 v-else>
              <v-layout wrap>
                <v-flex  v-for="(item, index) in filteredFacilities"
                :key="index"
                xs12 sm3 pa-1>
        <span>{{ formatFacilityName(item) }}</span>
      </v-flex>
              </v-layout>
             </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm10 text-left><v-divider></v-divider></v-flex>
            <v-flex xs12 lg10>
              <v-layout wrap mt-2 v-if="hotelData">
                <v-flex
                  xs12
                  px-0
                  px-md-2
                 ><span  style="
                    font-family: LexendFont;
                    font-weight: 500;
                    font-size: 22px;
                  "
                  >Policies </span></v-flex
                >
              <v-flex xs12 py-2 v-if="hotelData.policies">
                <v-layout wrap>
                      <v-flex
                        xs12
                        align-self-center
                        text-left
                        px-2 pb-1
                        v-if="hotelData.policies"
                      >
                      
                        <v-layout
                          wrap
                          v-if="hotelData.policies.length > 0"
                         
                        >
                          <v-flex
                            xs12
                            v-for="(item, i) in hotelData.policies"
                            :key="i"
                          >
                            <span
                              style="
                                font-family: LexendFont;
                                font-weight: 300;
                                font-size: 16px;
                                color: black;
                              "
                            >
                            <v-icon small color="black">mdi-arrow-right</v-icon>  {{ item }}</span
                            >
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
              </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>

          <v-layout wrap>
            <v-flex
              xs12
              px-0
              px-md-2
              pt-2
              style="font-family: LexendFont; font-weight: 500; font-size: 22px"
              >Photos</v-flex
            >
            <v-flex px-0 px-md-2 xs12 sm10 v-if="OtherImgs">
              <v-layout wrap v-if="OtherImgs.length > 0">
                <v-flex
                  xs12
                  sm4
                  md3
                  v-for="(item, i) in OtherImgs"
                  :key="i"
                  class="pa-1"
                >
                  <img
                    :src="mediaUURL + item"
                    style="object-fit: cover"
                    height="200px"
                    width="200px"
                  />
                  <v-layout wrap>
                    <v-flex align-self-start text-right pa-0><span @click="removeImageArray(i)" style="color: red;cursor: pointer;">Remove </span> 
                      <v-avatar color="#FF3434" size="15">
                        <v-icon color="#FFF" small @click="removeImageArray(i)">
                          mdi-close
                        </v-icon>
                      </v-avatar>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <!-- <v-flex
                  @click="
                    $router.push('/HouseboatGallery?id=' + $route.query.id)
                  "
                  xs12
                  sm1
                  class="mr-2"
                  align-self-center
                  text-center
                >
                  <span
                    style="
                      font-family: LexendFont;
                      font-weight: 600;
                      font-size: 10px;
                      color: rgba(241, 115, 67, 1);
                      cursor: pointer;
                    "
                    >View All <v-icon small>mdi-arrow-right</v-icon></span
                  ></v-flex
                > -->
              </v-layout>
              <v-layout wrap v-else>
                <v-flex xs12>
                  <span
                    style="
                      font-family: LexendFont;
                      font-weight: 500;
                      font-size: 16px;
                    "
                    >No images found</span
                  >
                </v-flex>
              </v-layout>
            </v-flex>

          </v-layout>
        </v-card>
        <v-dialog width="800px" v-model="editdialogue">
          <v-card width="800px" class="pa-4">
            <v-layout wrap justify-center>
              <v-flex xs11 text-center
                ><span
                  style="
                    color: #f17343;
                    font-weight: 400;
                    font-size: 25px;
                    font-family: LexendFont;
                    text-transform: none;
                  "
                  >Edit</span
                ></v-flex
              >
              <v-flex xs1 text-right>
                <v-icon @click="editdialogue = false" color="#f17343"
                  >mdi-close-box</v-icon
                ></v-flex
              >
              <v-flex xs8 text-center py-4>
                <v-divider></v-divider>
              </v-flex>
            </v-layout>
          

            <v-card-actions class="pt-3">
              <v-layout wrap>
                <v-spacer></v-spacer>
                <v-flex xs2>
                  <v-btn
                    color="#f17343"
                    class="buttons1"
                    dark
                    block
                    @click="editProfile()"
                    >OK</v-btn
                  >
                </v-flex>
              </v-layout>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </div>
</template> 
<script>
import axios from "axios";
export default {
  data() {
    return {
      showSnackbar: false,
      timeout: 2000,
      ServerError: false,
      fileUploadDialog2: false,
      appLoading: false,
      hotelData: {},
      boatLicenseProof: "",
      attachments: null,
      leaseProof: "",
      attachments2: null,
      formData: new FormData(),
      formData2: new FormData(),
      editdialogue:false,
      menu2: false,
      menu: false,
      tripDetails: [],
      tab: null,
      fileUploadDialog: false,
      selectedFile: null,
      msg: null,
      page: 1,
      limit: 20,
      deleteialog: false,
      review: [],
      deleteId: "",
      editDialog: false,
      EditTrip1: false,
      editItem1: {},
      EditTrip2: false,
      editItem2: {},
      curItem: {},
      Item1: [],
      Item2: [],
      Item3: [],
      IsMealplan: true,
      IsMealplan2: true,
      // isFacility: true,
      FacilitiesData: {},
      OtherImgs: [],
      moreimg: false,
      DaySeasonedit: {},
      DayoffSeasonedit: {},
      contactDetails:{},
      editOverview: false,
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",

        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      phoneRules: [
        (v) => !!v || "phone is required",
        (v) => (v && v.length <= 10) || "invalid number",
        (v) => (v && v.length >= 10) || "invalid number",
        (v) => /^\d{10}$/.test(v) || "phone number must be a number",
      ],
      menu4: false,
      menu3: false,
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    page() {
      this.getData();
    },
  },
  computed: {
    isFacility() {
      return Object.values(this.FacilitiesData).some((value) => value === true);
    },
    filteredFacilities() {
      return Object.keys(this.FacilitiesData).filter(
        (facility) => this.FacilitiesData[facility]
      );
    },
  },
  methods: {
    formatFacilityName(facility) {
      // Optional: Format the facility name to make it more readable
      return facility
        .replace(/([A-Z])/g, " $1")
        .replace(/Or/g, " or ")
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/^./, (str) => str.toUpperCase());
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/vendor/hotel/view",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.hotelData = response.data.data;
          this.contactDetails=this.hotelData.contactDetails;
          this.OtherImgs = this.hotelData.additionalImages;

          if (this.OtherImgs && this.OtherImgs.length > 4) {
            this.OtherImgs = this.OtherImgs.slice(0, 4);
            this.moreimg = true;
          }
          this.FacilitiesData = this.hotelData.facilities;
        })
        .catch((err) => {
          //   this.ServerError = true;
          console.log(err);
        });
    },
    removeImageArray(i) {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/hotel/removephoto",
        data: {
          position: i+1,
          id: this.$route.query.id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      }).then((response) => {
        this.appLoading = false;
        if (response.data.status == true) {
          this.msg = response.data.msg;
          this.showSnackBar = true;
          this.getData();
        } else {
          this.msg = response.data.msg;
          this.showSnackBar = true;
        }
      });
      // }
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      // var hours = dt.getHours();
      // var minutes = dt.getMinutes();
      dt = dt.toString();
      // var ampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      // +
      // " , " +
      // hours +
      // ":" +
      // minutes +
      // " " +
      // ampm;

      return strTime;
    },
  },
};
</script>
<style scoped>
.gr1 {
  background: linear-gradient(
    to bottom,
    rgba(255, 125, 20, 1),
    rgba(241, 115, 67, 1)
  );
}
.gr2 {
  background: rgb(34, 193, 195);
  background: linear-gradient(
    0deg,
    rgba(34, 193, 195, 1) 0%,
    rgba(253, 187, 45, 1) 100%
  );
}
/* .basil {
  background-color: #FFFBE6 !important;
} */
.basil--text {
  color: #356859 !important;
}
</style>